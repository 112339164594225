<template src="./useractivebymonth.html"></template>
<style scopred src="./useractivebymonth.css"></style>

<script>
import Loading from 'vue-loading-overlay'
import applicationConstants from '@/constants/AppConstants.vue'
import reportServices from '@/services/ReportService.vue'
import Modal from '@/components/modal/notification/modal'
import moment from 'moment'
import JsonToExcel from 'vue-json-excel'
import Pagination from '@/components/vtable/vtpagination.vue'

export default {
    name: 'UserReportByMonth',
    components: {
        Loading, Modal, JsonToExcel, Pagination
    },
    data () {
        return {
            selectedYear: "",
            selectedMonth: "",
            yearList: [],
            monthList: [],
            usersResponseData: [],
            userTableDataColumns:['Year', 'Month', 'UsersWithTxnInPast12Months'],
            userTableOptions: { },
            enablePaginateData: applicationConstants.enableLazyLoading,
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            csvFields: {
                "Year": "Year",
                "Month": "Month",
                "UsersWithTxnInPast12Months": "UsersWithTxnInPast12Months"
            }
        }
    },
    methods: {
        searchUserReport () {
            this.loaderVisible = true
            reportServices.methods.GetUsersActiveByMonth ({
                Month: this.selectedMonth.toString() ? this.selectedMonth : null,
                Year: this.selectedYear ? this.selectedYear : null
            })
            .then (res => {
                this.usersResponseData = res.csGetUsersActiveListByMonthResponse.ActiveUserData?.RptActiveUserData
                this.usersResponseData = this.usersResponseData ? (Array.isArray(this.usersResponseData) ? this.usersResponseData : [this.usersResponseData]) : []
                if (this.enablePaginateData) {
                    this.$refs.paginationRef.selectedPage = 1
                } else {
                    this.$refs.vueClientTable.setPage(1)
                }
                this.loaderVisible = false
            })
            .catch(err => {
                this.usersResponseData = []
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        reponseModifier (usersData) {
            if (usersData) {
                usersData.forEach(user => {
                    Object.keys(user).map(key => user[key] = user[key]?._text ? user[key]._text : null)
                })
            }
            return usersData ? usersData : []
        },
        clearAll() {
            this.selectedYear = ""
            this.selectedMonth = ""
        },
        exportTableData () {
            if (this.usersResponseData.length > 0) {
                return this.reponseModifier(JSON.parse(JSON.stringify(this.usersResponseData)))
            } 
        },
        getYearsList(range) {
            return Array.from({length: range}, (v, i) => (new Date().getFullYear()) - range + i + 1);
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed: {
        userTableData () {
            let usersData = []
            if (this.usersResponseData && this.usersResponseData.length > 0) {
                usersData = JSON.parse(JSON.stringify(this.usersResponseData))
                if(this.enablePaginateData) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    usersData = usersData.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                usersData = this.reponseModifier(JSON.parse(JSON.stringify(usersData)))
                const updateTotalRecords = () => {
                    if(this.$refs.paginationRef) {
                        this.$refs.paginationRef.totalRecords = this.usersResponseData?.length ?? 0
                    }
                }
                if (this.enablePaginateData) {
                    updateTotalRecords();
                }
            }
            return usersData
        }
    },
    mounted () {
        this.yearList = ((this.getYearsList(50)).map(year => { return {Text: year, Value: year}})).sort((a,b)=> b.Text - a.Text)
        this.monthList = (moment.months()).map((mon, index) => {return { Text: mon, Value: index + 1}})
    }
}
</script>